<div class="container-fluid bg-light">
    <div class="row">
        <div class="col-md-12 mx-auto hOnePageTitle">
            <h1 class="p-4 text-center">Privacy Policy</h1>
        </div>
    </div>
</div>
<div class="container single-page mb-5">
    <div class="row">
        <div class="col-md-12 py-5">
            <div [innerHTML]="content.pageContent"></div>
        </div>          
    </div>
</div>