<div id="carouselExample" class="carousel slide" data-bs-ride="carousel">
	<div class="carousel-inner">
	  <div class="carousel-item banner active">
		<div class="container">
			<div class="row align-items-center justify-content-center">
				<div class="col-md-6">
					<div class="">
						<img src="assets/images/banner-image.png" class="img-fluid">
					</div>
				</div>
				<div class="col-md-6">
					<div class="banner-content stores">
						<span>Vowpay</span>
						<h1>Fast, Secure & Affordable Remittance</h1>
						<p>Vowpay is authorised and regulated by the Financial Conduct Authority in the United Kingdom FRN
							738233. Company number 09200297.</p>
						<a href="https://play.google.com/store/apps/details?id=com.vowpay.vowpay" target="_blank"><img src="assets/images/googleplay_white.png"></a>
						<span class="mx-2"></span>
						<a href="https://apps.apple.com/us/app/vowpay/id6739277893" target="blank"><img src="assets/images/appstore_white.png"></a>
					</div>
				</div>
			</div> <!-- / .row -->
		</div> <!-- / .container -->
	  </div>
	  <!-- <div class="carousel-item banner-wv">
		<div class="container">
			<div class="row align-items-center justify-content-center">
				<div class="col-md-6">
					<div class="banner-content stores">
						<span>Waafi By Vowpay</span>
						<h1>Send Money Globally with the Waafi App Fast, Secure, and Affordable.</h1>
						<p>Waafi by Vowpay offered by Vowpay Ltd, a UK-authorised payment institution. Download the Waafi
							App Now and Start Sending Money Today."
						</p>
						<a href="https://play.google.com/store/apps/details?id=com.safarifone.waafi&hl=en_IN"
							target="_blank"><img src="assets/images/googleplay_white.png"></a>
						<span class="mx-2"></span>
						<a href="https://apps.apple.com/us/app/waafi-app/id1164281864" target="blank"><img
								src="assets/images/appstore_white.png"></a>
					</div>
				</div>
				<div class="col-md-6 mx-auto">
						<img src="assets/images/banner-img.png" class="img-fluid" width="90%">
				</div>
			</div> 
		</div>
	  </div> -->
	</div>
	<!-- <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
	  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
	  <span class="visually-hidden">Previous</span>
	</button>
	<button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
	  <span class="carousel-control-next-icon" aria-hidden="true"></span>
	  <span class="visually-hidden">Next</span>
	</button> -->
  </div>

<section class="section about" id="aboutus">
	<div class="container">
		<div class="row">
			<div class="col-lg-6">
				<div class="about-item">
					<h2>About Vowpay</h2>
					<div><p>Welcome to Vowpay, your trusted partner for international money transfers from the United Kingdom to destinations around the globe. Our mission is to make sending money effortless, fast, and secure, empowering you to stay connected with your loved ones no matter where they are.</p><p>At Vowpay, we pride ourselves on delivering exceptional service through three unique benefits:</p><ol><li><strong>Instant Transfers:</strong> Experience seamless and lightning-fast delivery of your funds, ensuring your money reaches its destination without delay.</li><li><strong>Competitive Rates:</strong> Benefit from our transparent and affordable exchange rates, designed to give you the best value for every transfer.</li><li><strong>Low Transfer Fees:</strong> Maximize your transfer amount with our cost-effective fee structure, making it easier and more economical to send money abroad.</li></ol></div>
					<a routerLink="aboutus" class="btn btn-primary text-white">Read more <i
							class="fa fa-angle-right ml-2"></i></a>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="about-img mb-5 mb-lg-0">
					<img src="assets/images/about-us.jpg" alt="" class="img-fluid">
				</div>
			</div>
		</div>
	</div>
</section>
<!-- 
<section class="section about bg-light" id="aboutus">
	<div class="container">
		<div class="row">
			<div class="col-lg-6">
				<div class="about-img mb-5 mb-lg-0">
					<img src="assets/images/partners.jpg" alt="" class="img-fluid">
				</div>
			</div>
			<div class="col-lg-6">
				<div class="about-item">
					<h4>Vowpay Ltd and Waafi App Join Forces to Revolutionise Global Money Transfers for UK Residents</h4>
					<div>
						<p>We’re thrilled to announce an exciting strategic partnership between Vowpay Ltd, a UK-based Authorised Payment institution, and Waafi App, a cutting-edge global payment platform. Together, we’re offering UK residents a seamless, secure, and cost-effective way to send money globally.</p>
						<p>With this collaboration, users can now enjoy the convenience of Waafi App’s user-friendly interface, a wide payout connectivity combined with Vowpay’s authorisation, ensuring fast, reliable, and transparent international transfers. Whether you’re supporting loved ones, managing overseas expenses, or conducting business transactions, this partnership brings you a unique opportunity to send money with ease and confidence.</p>
						<p>Experience the future of global payments today with Vowpay and Waafi App – where innovation meets trust.</p>
						<p class="blockquote"><em>"Send smarter. Send faster. Send with Vowpay and Waafi App."</em> </p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section> -->

<section>
	<div class="container-fluid benefits text-white py-5">
		<div class="container">
			<div class="row text-center">
				<div class="col-lg-12">
					<div class="my-4">
						<h2>Benefits of using Vowpay</h2>
					</div>
				</div>
			</div>
			<div class="row pt-5">
				<div class="col-md-6">
					<div>
						<img src="assets/images/benefits.png" class="card-img-top">
					</div>
				</div>
				<div class="col-md-6">
					<div>
						<div>
							<img src="assets/images/benefits-icon.png" style="width: 30px;" class="float-start me-4">
							<h5 class="text-dark">Instant Transfer</h5>
							<p class="mt-4">At Vowpay, we recognize that time is crucial when it comes to sending money to your loved ones. That's why we provide Instant Transfer, which guarantees that your funds are delivered swiftly and seamlessly to the recipient's mobile wallet, bank account, or selected cash-out location.</p>
						</div>
						<div>
							<img src="assets/images/benefits-icon.png" style="width: 30px;" class="float-start me-4">
							<h5 class="text-dark">Direct Transfer</h5>
							<p class="mt-4">At Vowpay, your trust is our top priority. Our Secure & Direct Transfer service guarantees that your money arrives at its destination safely and efficiently. We utilize a state-of-the-art system with secure API connections to ensure immediate payout processing to our partners, eliminating unnecessary delays.</p>
						</div>
						<div>
							<img src="assets/images/benefits-icon.png" style="width: 30px;" class="float-start me-4">
							<h5 class="text-dark">Track Transfer</h5>
							<p class="mt-4">At Vowpay, we keep you informed with our "Track Your Transfer" feature, providing real-time monitoring for complete transparency and peace of mind. With Track Your Transfer, you can Stay Updated, Monitor Progress, Gain Transparency and Resolve Issues Quickly.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="section howitworks" id="howitworks">
	<div class="container">
		<div class="row mb-5">
			<div class="col-md-12">
				<div class="text-center">
					<h2>How To Send Using Vowpay</h2>
				</div>
			</div>
		</div>
		<div class="row text-center">
			<div class="col-md-4">
				<img src="assets/images/add-user.png" class="me-4"><br>
				<h5 class="text-dark">Create an account</h5>
				<p>1. Enter your mobile, name, residential & email. <br>
				2. Send us your proof of identity and address. <br>
				3. You are good to go.</p>
			</div>
			<div class="col-md-4">
				<img src="assets/images/money-transfer.png" class="me-4"><br>
				<h5 class="text-dark">Transfer Money</h5>
				<p>1. Enter amount to send. <br>
				2. Create a new beneficiary. <br>
				3. Make payment.</p>
			</div>
			<div class="col-md-4">
				<img src="assets/images/time-tracking.png" class="me-4"><br>
				<h5 class="text-dark">Track Transfer</h5>
				<p>1. Active updates on transfer. <br>
				2. Receive confirmation <br>
				3. And its done!!.</p>
			</div>
		</div>
	</div>
</section>